import { Component } from 'react'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { IntlProvider } from 'react-intl'
import * as locales from '@material-ui/core/locale'

import { messages as messagesEn } from './translations/en.js'
import { messages as messagesJa } from './translations/ja.js'

const messages = {
  en: messagesEn,
  ja: messagesJa,
}

class Context extends Component {
  constructor(props) {
    super(props)
    this.state = {
      locale: 'en',
    }
  }

  componentDidMount() {
    const language = navigator.language.split(/[-_]/)[0]
    if (language === 'ja') {
      this.setState({
        locale: language,
      })
    }
  }

  render() {
    return (
      <IntlProvider
        messages={messages[this.state.locale]}
        locale={this.state.locale}
      >
        <ThemeProvider
          theme={createMuiTheme(locales[this.state.locale])}
        >
          {this.props.children}
        </ThemeProvider>
      </IntlProvider>
    )
  }
}

export default Context
