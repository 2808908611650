import { Component } from 'react'
import clsx from 'clsx'
import { withStyles } from '@material-ui/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Container from '@material-ui/core/Container'
import ReactMarkdown from 'react-markdown'

const styles = (theme) => ({
  base: {
    '& pre': {
      padding: '0.5em',
    }
  },
  cli: {
    '& pre': {
      backgroundColor: '#000',
      color: '#fff',
    }
  },
  python: {
    '& pre': {
      border: '1px solid #cfcfcf',
      borderRadius: '4px',
      background: '#f7f7f7',
    }
  }
})

class SimpleExamples extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selected: 0,
    }
  }

  getScripts() {
    const { sourceId } = this.props
    const sourceURL = new URL(`/v1/sources/${sourceId}/messages/`, window.location.href).href
    return [{
      lang: 'CLI(curl)',
      className: 'cli',
      script: `
\`\`\`
$ curl -X PUT -H 'Content-Type: text/plain' -d 'Notification!' \\
    ${sourceURL}
\`\`\`
`,
    }, {
      lang: 'Python(requests)',
      className: 'python',
      script: `
\`\`\`
import requests
requests.put('${sourceURL}',
             json={'text': 'Notification!'})
\`\`\`
    `,
    }]
  }

  render() {
    const { classes } = this.props
    const scripts = this.getScripts()
    return (
      <Container maxWidth={this.props.maxWidth || 'md'}>
        <Tabs
          value={this.state.selected}
          onChange={(event, newValue) => this.setState({ selected: newValue })}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          {scripts.map((script) => <Tab
              key={script.lang}
              label={script.lang}
            />)}
        </Tabs>
        {scripts[this.state.selected] && scripts[this.state.selected].script &&
          <ReactMarkdown className={ clsx(classes.base, classes[scripts[this.state.selected].className]) }>
            {scripts[this.state.selected].script}
          </ReactMarkdown>}
      </Container>
    )
  }
}

export default withStyles(styles)(SimpleExamples)
