import { Component } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { withStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'

const styles = (theme) => ({
  revoke: {
    textAlign: 'right',
  },
  addToSlack: {
    '& .MuiButton-label': {
      justifyContent: 'start',
    },
    '& img': {
      height: '3em',
    },
  },
})

class SlackEditor extends Component {
  constructor(props) {
    super(props)

    this.state = {
      revoking: false,
    }
  }

  addToSlack() {
    if (!this.props.onContextRequest) {
      return
    }
    this.props.onContextRequest((context) => {
      window.location.href = `${context.baseurl}/slack/authorize`
    })
  }

  revoke() {
    if (!this.props.onRevoke) {
      return
    }
    this.setState({
      revoking: true,
    }, () =>
      this.props.onRevoke(() =>
        this.setState({
          revoking: false,
        })
      )
    )
  }

  changeChannel(event) {
    if (!this.props.onUpdate) {
      return
    }
    this.props.onUpdate({
      channel: event.target.value === '' ? null : event.target.value,
    })
  }

  renderConnected() {
    const { classes } = this.props
    return <>
      <Grid item xs={4}>
        <TextField
            label={<FormattedMessage id='slackWorkspace'/>}
            defaultValue={this.props.sink.data.teamName || ''}
            InputProps={{
              readOnly: true,
            }}
          />
      </Grid>
      <Grid item xs={4}>
        <FormControl>
          <InputLabel id={`label-${this.props.sink.id}-channel`}>
            <FormattedMessage id='slackChannel'/>
          </InputLabel>
          <Select
            value={this.props.sink.data.channel || ''}
            onChange={(event) => this.changeChannel(event)}
            labelId={`label-${this.props.sink.id}-channel`}
          >
            <MenuItem value=''>
              <em><FormattedMessage id='slackChannelNotSelected'/></em>
            </MenuItem>
            {(this.props.sink.data.channels || []).map((channel) =>
              <MenuItem key={channel.id} value={channel.id}>#{channel.name}</MenuItem>)}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4} className={classes.revoke}>
        <Button
            variant="contained"
            color="secondary"
            onClick={() => this.revoke()}
            disabled={this.state.revoking}
          >
          <FormattedMessage id='revokeSink' />
        </Button>
      </Grid>
    </>
  }

  render() {
    const { classes, intl } = this.props
    return (
      <Grid container spacing={1}>
        {this.props.sink.data.credential ?
          this.renderConnected() :
          <Grid item xs={12}>
            <Button
              className={classes.addToSlack}
              onClick={() => this.addToSlack()}
            >
              <img
                alt={intl.formatMessage({ id: 'addToSlack' })}
                src='/images/btn-add-to-slack.svg'
              />
            </Button>
          </Grid>}
      </Grid>
    );
  }
}

export default withStyles(styles)(injectIntl(SlackEditor))
