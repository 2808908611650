import { Component } from 'react'
import clsx from 'clsx'
import { withStyles } from '@material-ui/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Container from '@material-ui/core/Container'
import ReactMarkdown from 'react-markdown'

const styles = (theme) => ({
  base: {
    '& pre': {
      padding: '0.5em',
    }
  },
  cli: {
    '& pre': {
      backgroundColor: '#000',
      color: '#fff',
    }
  },
  notebook: {
    '& pre': {
      border: '1px solid #cfcfcf',
      borderRadius: '4px',
      background: '#f7f7f7',
    }
  }
})

class Examples extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selected: 0,
    }
  }

  getScripts() {
    return [{
      lang: 'Jupyter Notebook',
      className: 'notebook',
      script: `
[![Binder](https://mybinder.org/badge_logo.svg)](https://mybinder.org/v2/gh/yacchin1205/notifyg/HEAD?filepath=notebooks%2FBinderExampleOfMagics.ipynb)
[![Open In Colab](https://colab.research.google.com/assets/colab-badge.svg)](https://colab.research.google.com/github/yacchin1205/notifyg/blob/master/notebooks/ColabExampleOfMagics.ipynb)

\`\`\`
!pip install --upgrade git+https://github.com/yacchin1205/notifyg.git
%load_ext notifyg.magics
\`\`\`

\`\`\`
%notifyg_init
\`\`\`

\`\`\`
%notifyg Notification!
\`\`\`

\`\`\`
%%notifyg
from datetime import datetime
datetime.now()
\`\`\`
`,
    }, {
      lang: 'CLI',
      className: 'cli',
      script: `
\`\`\`
$ pip install git+https://github.com/yacchin1205/notifyg
\`\`\`

\`\`\`
$ eval $(notifyg --init)
\`\`\`

\`\`\`
$ notifyg Notification!
\`\`\`
`
    }]
  }

  render() {
    const { classes } = this.props
    const scripts = this.getScripts()
    return (
      <Container maxWidth={this.props.maxWidth || 'md'}>
        <Tabs
          value={this.state.selected}
          onChange={(event, newValue) => this.setState({ selected: newValue })}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          {scripts.map((script) => <Tab
              key={script.lang}
              label={script.lang}
            />)}
        </Tabs>
        {scripts[this.state.selected] && scripts[this.state.selected].script &&
          <ReactMarkdown className={ clsx(classes.base, classes[scripts[this.state.selected].className]) }>
            {scripts[this.state.selected].script}
          </ReactMarkdown>}
      </Container>
    )
  }
}

export default withStyles(styles)(Examples)
