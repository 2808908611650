import { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { withStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Alert from '@material-ui/lab/Alert'
import AddIcon from '@material-ui/icons/Add'

import Sink from './Sink.js'
import NewSinkDialog from './NewSinkDialog.js'

const styles = (theme) => ({
  noSinks: {
    fontSize: '1.2em',
    textAlign: 'center',
    marginTop: '2em',
    marginBottom: '2em',
    color: '#888',
  },
  noSinksSelected: {
    padding: '1em',
  },
})

class Sinks extends Component {
  constructor(props) {
    super(props)

    this.state = {
      adding: false,
      newSinkDialogOpen: false,
    }
  }

  addNewSink(type) {
    if (!this.props.onAddSink) {
      return
    }
    this.setState({
      adding: true,
      newSinkDialogOpen: false,
    }, () => this.props.onAddSink(type,
      () => {
        this.setState({
          adding: false,
        })
      })
    )
  }

  openNewSinkDialog() {
    this.setState({
      newSinkDialogOpen: true,
    })
  }

  closeNewSinkDialog() {
    this.setState({
      newSinkDialogOpen: false,
    })
  }

  remove(sinkId, callback) {
    if (!this.props.onRemoveSink) {
      return
    }
    this.props.onRemoveSink(sinkId, callback)
  }

  requestContext(sinkId, callback) {
    if (!this.props.onContextRequest) {
      return
    }
    this.props.onContextRequest(sinkId, callback)
  }

  revoke(sinkId, callback) {
    if (!this.props.onRevoke) {
      return
    }
    this.props.onRevoke(sinkId, callback)
  }

  update(sinkId, props, callback) {
    if (!this.props.onUpdate) {
      return
    }
    this.props.onUpdate(sinkId, props, callback)
  }

  select(sinkId, selected) {
    if (!this.props.onSelect) {
      return
    }
    this.props.onSelect(sinkId, selected)
  }

  isSelected() {
    const sinks = this.props.sinks || []
    const selected = this.props.selected || {}
    return sinks.filter((sink) => selected[sink.id]).length > 0
  }

  render() {
    const { classes } = this.props
    const sinks = this.props.sinks || []
    return (
      <Container>
        {this.props.selectable && !this.isSelected() && <Container className={classes.noSinksSelected}>
            <Alert severity="error">
              <FormattedMessage id='noSinksSelected' />
            </Alert>
          </Container>}
        {sinks.length === 0 &&
          <Container className={classes.noSinks}>
            <FormattedMessage id='noSinks' />
          </Container>}
        {sinks.map((sink) =>
          <Sink
            key={sink.id}
            sink={sink}
            selectable={this.props.selectable}
            selected={(this.props.selected || {})[sink.id] || false}
            onRemove={(callback) => this.remove(sink.id, callback)}
            onContextRequest={(callback) => this.requestContext(sink.id, callback)}
            onRevoke={(callback) => this.revoke(sink.id, callback)}
            onUpdate={(props, callback) => this.update(sink.id, props, callback)}
            onSelect={(selected) => this.select(sink.id, selected)}
          />)}
        <Button
          fullWidth
          variant="contained"
          color="primary"
          disabled={this.state.adding}
          onClick={() => this.openNewSinkDialog()}
        >
          <AddIcon />
          <FormattedMessage id={this.state.adding ? 'addingSink' : 'addSink'} />
        </Button>
        <NewSinkDialog
          open={this.state.newSinkDialogOpen}
          onAddSink={(type) => this.addNewSink(type)}
          onClose={() => this.closeNewSinkDialog()}
        />
      </Container>
    )
  }
}

export default withStyles(styles)(Sinks)
