import { Component } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/styles'
import { withRouter } from 'react-router'
import NameLabel from './NameLabel.js'

const styles = (theme) => ({
  root: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
  expiration: {
    textAlign: 'right',
  },
  toSinkIcon: {
    width: 32,
    height: 32,
  },
  toSinks: {
    textAlign: 'center',
    marginTop: '2em',
    marginBottom: '2em',
  },
})

class Client extends Component {
  changeClientName(name, callback) {
    if (!this.props.onClientUpdate) {
      return
    }
    this.props.onClientUpdate(this.props.client.id, { name }, callback)
  }

  authorizeClient(callback) {
    if (!this.props.onClientAuthorize) {
      return
    }
    this.props.onClientAuthorize(this.props.client.id, callback)
  }

  renderNameLabel() {
    const { intl } = this.props
    const client = this.props.client || { data: {} }
    return <NameLabel
      editable={this.props.editable}
      value={client.data.name || intl.formatMessage({ id: 'anonymousClient' })}
      onChange={(value, callback) => this.changeClientName(value, callback)}
    />
  }

  render() {
    const { classes } = this.props
    const client = this.props.client || { data: {} }
    return (
      <Box>
        <Paper className={classes.root}>
          <Grid container spacing={1}>
            <Grid item xs={7}>
              {this.props.link ? <a href={this.props.link(client)}>
                  {this.renderNameLabel()}
                </a> : this.renderNameLabel()}
            </Grid>
            <Grid item xs={5} className={classes.expiration}>
              {this.props.authorizable && !client.data.authorized &&
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.authorizeClient()}
                >
                  <FormattedMessage id='authorizeClient' />
                </Button>}
            </Grid>
          </Grid>
        </Paper>
      </Box>
    )
  }
}

export default withStyles(styles)(withRouter(injectIntl(Client)))
