import { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import axios from 'axios'
import { withStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import HomeIcon from '@material-ui/icons/Home'
import DevicesIcon from '@material-ui/icons/Devices'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import DashboardPage from './DashboardPage.js'
import Source from '../components/Source.js'
import Client from '../components/Client.js'

const DEFAULT_VISIBLE_SOURCES = 3
const styles = (theme) => ({
  title: {
    fontSize: '2em',
  },
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
})

class UserPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sinks: null,
      selectedSinks: null,
      expand: false,
      selectedTab: 0,
    }
  }

  componentDidMount() {
    this.load()
      .then((data) => {
        this.setState(data, () => {
          const mailSinks = data.sinks.filter((sink) => sink.data.type === 'mail')
          if (mailSinks.length > 0) {
            return
          }
          this.addSink('mail')
        })
      })
      .catch((error) => {
        this.showError('errorUserLoad', error)
      })
  }

  addSource(callback) {
    this.performAddSource()
      .then((resp) => {
        window.location.href = resp.registerurl
      })
      .catch((error) => {
        this.showError('errorAddSource', error)
        if (!callback) {
          return
        }
        callback()
      })
  }

  addSink(type, callback) {
    this.performAddSink(type)
      .then((data) => {
        this.setState(data, () => {
          if (!callback) {
            return
          }
          callback()
        })
      })
      .catch((error) => {
        this.showError('errorUpdateSink', error)
        if (!callback) {
          return
        }
        callback()
      })
  }

  removeSink(sinkId, callback) {
    this.performRemoveSink(sinkId)
      .then((data) => {
        this.setState(data, () => {
          if (!callback) {
            return
          }
          callback()
        })
      })
      .catch((error) => {
        this.showError('errorUpdateSink', error)
        if (!callback) {
          return
        }
        callback()
      })
  }

  createContext(sinkId, callback) {
    this.performCreateContext(sinkId)
      .then((data) => {
        if (!callback) {
          return
        }
        callback(data)
      })
      .catch((error) => {
        this.showError('errorUpdateSink', error)
        if (!callback) {
          return
        }
        callback()
      })
  }

  revokeSink(sinkId, callback) {
    this.performRevokeSink(sinkId)
      .then((data) => {
        this.setState(data, () => {
          if (!callback) {
            return
          }
          callback()
        })
      })
      .catch((error) => {
        this.showError('errorUpdateSink', error)
        if (!callback) {
          return
        }
        callback()
      })
  }

  updateSink(sinkId, props, callback) {
    this.performUpdateSink(sinkId, props)
      .then((data) => {
        this.setState(data, () => {
          if (!callback) {
            return
          }
          callback()
        })
      })
      .catch((error) => {
        this.showError('errorUpdateSink', error)
        if (!callback) {
          return
        }
        callback()
      })
  }

  updateSource(sourceId, props, callback) {
    this.performUpdateSource(sourceId, props)
      .then((data) => {
        this.setState(data, () => {
          if (!callback) {
            return
          }
          callback()
        })
      })
      .catch((error) => {
        this.showError('errorUpdateSource', error)
        if (!callback) {
          return
        }
        callback()
      })
  }

  updateClient(clientId, props, callback) {
    this.performUpdateClient(clientId, props)
      .then((data) => {
        this.setState(data, () => {
          if (!callback) {
            return
          }
          callback()
        })
      })
      .catch((error) => {
        this.showError('errorUpdateClient', error)
        if (!callback) {
          return
        }
        callback()
      })
  }

  authorizeClient(clientId, callback) {
    this.performAuthorizeClient(clientId)
      .then((data) => {
        this.setState(data, () => {
          if (!callback) {
            return
          }
          callback()
        })
      })
      .catch((error) => {
        this.showError('errorAuthorizeClient', error)
        if (!callback) {
          return
        }
        callback()
      })
  }

  selectSink(sinkId, selected) {
    const state = this.state.selectedSinks || {}
    if (state[sinkId] === selected) {
      return
    }
    const newState = Object.assign({}, state)
    newState[sinkId] = selected

    this.performSelectSink(newState)
      .then((data) => {
        this.setState(data)
      })
      .catch((error) => {
        this.showError('errorUpdateSource', error)
      })
  }

  async load() {
    const source = await this.activate()
    const client = await this.loadClient()
    const firestore = this.props.firestore
    const sourceRecords = await firestore
      .collection('user')
      .doc(this.props.user.uid)
      .collection('source')
      .orderBy('created', 'desc')
      .get()
    const sources = sourceRecords.docs.map((source) => ({
      id: source.id,
      data: source.data(),
    }))
    const clientRecords = await firestore
      .collection('user')
      .doc(this.props.user.uid)
      .collection('client')
      .orderBy('accessed', 'desc')
      .get()
    const clients = clientRecords.docs.map((client) => ({
      id: client.id,
      data: client.data(),
    }))
    const sinkRecords = await firestore
      .collection('user')
      .doc(this.props.user.uid)
      .collection('sink')
      .orderBy('updated', 'desc')
      .get()
    const sinks = sinkRecords.docs.map((sink) => ({
      id: sink.id,
      data: sink.data(),
    }))
    return Object.assign({}, client, source, {
      sinks,
      sources,
      clients,
    })
  }

  async activate() {
    if (!window.location.pathname.match(/^\/r\/([0-9a-zA-Z_-]+)$/)) {
      return {}
    }
    const sourceId = window.location.pathname.substring(3)
    const firestore = this.props.firestore
    const sourceDocRef = firestore
      .collection('user')
      .doc(this.props.user.uid)
      .collection('source')
      .doc(sourceId)
    const sourceRef = await sourceDocRef.get()
    if (sourceRef.exists && sourceRef.data().activated) {
      return {
        source: {
          id: sourceRef.id,
          data: sourceRef.data(),
        },
        selectedSinks: sourceRef.data().selectedSinks || null,
      }
    }
    const created = Date.now()
    await sourceDocRef.set({
      created,
      name: null,
      activated: false,
      updated: created,
    })
    const added = await firestore
      .collection('context')
      .add({
        uid: this.props.user.uid,
        source: sourceId,
        created,
      })
    const baseurl = new URL(`/context/${added.id}`, window.location.href).href
    await axios.post(`${baseurl}/source/activate`)
    const uSourceRef = await sourceDocRef.get()
    if (!uSourceRef.exists) {
      throw new Error('Unexpected error')
    }
    return {
      source: {
        id: uSourceRef.id,
        data: uSourceRef.data(),
      },
      selectedSinks: uSourceRef.data().selectedSinks || null,
    }
  }

  async loadClient() {
    if (!window.location.pathname.match(/^\/c\/([0-9a-zA-Z_-]+)$/)) {
      return {}
    }
    const clientId = window.location.pathname.substring(3)
    const firestore = this.props.firestore
    const clientDocRef = firestore
      .collection('user')
      .doc(this.props.user.uid)
      .collection('client')
      .doc(clientId)
    const clientRef = await clientDocRef.get()
    return {
      client: {
        id: clientRef.id,
        data: clientRef.exists ? clientRef.data() : {},
      },
    }
  }

  async performAddSource(type) {
    const resp = await axios.put('/v1/sources', {
      nosecret: true,
    })
    return resp.data
  }

  async performAddSink(type) {
    const firestore = this.props.firestore
    const created = Date.now()
    await firestore
      .collection('user')
      .doc(this.props.user.uid)
      .collection('sink')
      .add({
        type,
        created,
        updated: created,
      })
    return await this.load()
  }

  async performRemoveSink(sinkId) {
    const firestore = this.props.firestore
    await firestore
      .collection('user')
      .doc(this.props.user.uid)
      .collection('sink')
      .doc(sinkId)
      .delete()
    return await this.load()
  }

  async performCreateContext(sinkId) {
    const firestore = this.props.firestore
    const created = Date.now()
    const added = await firestore
      .collection('context')
      .add({
        uid: this.props.user.uid,
        sink: sinkId,
        created,
      })
    return {
      id: added.id,
      created,
      baseurl: new URL(`/context/${added.id}`, window.location.href).href,
    }
  }

  async performRevokeSink(sinkId) {
    const firestore = this.props.firestore
    const sinkDocRef = firestore
      .collection('user')
      .doc(this.props.user.uid)
      .collection('sink')
      .doc(sinkId)
    const sinkRef = await sinkDocRef.get()
    if (!sinkRef.exists) {
      throw new Error(`Unknown Sink: ${sinkId}`)
    }
    const context = await this.performCreateContext(sinkId)
    await sinkDocRef.set(Object.assign(sinkRef.data(), {
      updated: Date.now(),
      revokeRequest: context.id
    }))
    await axios.post(`${context.baseurl}/${sinkRef.data().type}/revoke`)
    await sinkDocRef.update({
      updated: Date.now(),
      revokeRequest: null,
    })
    return await this.load()
  }

  async performUpdateSink(sinkId, props) {
    const firestore = this.props.firestore
    await firestore
      .collection('user')
      .doc(this.props.user.uid)
      .collection('sink')
      .doc(sinkId)
      .update(props)
    return await this.load()
  }

  async performUpdateSource(sourceId, props) {
    const firestore = this.props.firestore
    await firestore
      .collection('user')
      .doc(this.props.user.uid)
      .collection('source')
      .doc(sourceId)
      .update(props)
    return await this.activate()
  }

  async performUpdateClient(clientId, props) {
    const firestore = this.props.firestore
    await firestore
      .collection('user')
      .doc(this.props.user.uid)
      .collection('client')
      .doc(clientId)
      .update(props)
    return await this.loadClient()
  }

  async performAuthorizeClient(clientId) {
    const firestore = this.props.firestore
    const created = Date.now()
    const clientDocRef = firestore
      .collection('user')
      .doc(this.props.user.uid)
      .collection('client')
      .doc(clientId)
    await clientDocRef.set({
      created,
      name: null,
      authorized: false,
      updated: created,
    })
    const added = await firestore
      .collection('context')
      .add({
        uid: this.props.user.uid,
        client: clientId,
        created,
      })
    const baseurl = new URL(`/context/${added.id}`, window.location.href).href
    await axios.post(`${baseurl}/client/authorize`)
    return await this.loadClient()
  }

  async performSelectSink(selectedSinks) {
    const firestore = this.props.firestore
    await firestore
      .collection('user')
      .doc(this.props.user.uid)
      .collection('source')
      .doc(this.state.source.id)
      .update({
        selectedSinks,
        updated: Date.now(),
      })
    return await this.activate()
  }

  showError(message, error) {
    console.error(message)
    console.error(error)
    if (!this.props.onError) {
      return
    }
    this.props.onError(message, error)
  }

  collapseSources() {
    this.setState({
      expand: false,
    })
  }

  expandSources() {
    this.setState({
      expand: true,
    })
  }

  renderSources() {
    let sources = this.state.sources || []
    if (!this.state.expand && sources.length > DEFAULT_VISIBLE_SOURCES) {
      sources = sources.slice(0, DEFAULT_VISIBLE_SOURCES)
    }
    return <>
      {sources.map((source) => <Source
          key={source.id}
          source={source}
          link={(source) => `/r/${source.id}`}
        />)}
      {this.state.expand ?
        <Button
          fullWidth
          onClick={() => this.collapseSources()}
        >
          <ExpandLessIcon />
        </Button> : <Button
          fullWidth
          onClick={() => this.expandSources()}
        >
          <ExpandMoreIcon />
        </Button>}
    </>
  }

  renderSource() {
    const source = this.state.source || { data: {} }
    const { classes, intl } = this.props
    return <>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" to='/' className={classes.link}>
          <HomeIcon className={classes.icon} />
          <FormattedMessage id='toDashboard' />
        </Link>
        <Typography color="textPrimary" className={classes.link}>
          <DevicesIcon className={classes.icon} />
          {source.data.name || intl.formatMessage({ id: 'anonymousSource' })}
        </Typography>
      </Breadcrumbs>
      <Source
        editable={true}
        source={this.state.source}
        sinks={this.state.sinks}
        selectedSinks={this.state.selectedSinks}
        onAddSink={(type, callback) => this.addSink(type, callback)}
        onRemoveSink={(sinkId, callback) => this.removeSink(sinkId, callback)}
        onContextRequest={(sinkId, callback) => this.createContext(sinkId, callback)}
        onRevoke={(sinkId, callback) => this.revokeSink(sinkId, callback)}
        onSinkUpdate={(sinkId, props, callback) => this.updateSink(sinkId, props, callback)}
        onSinkSelect={(sinkId, selected) => this.selectSink(sinkId, selected)}
        onSourceUpdate={(sinkId, props, callback) => this.updateSource(sinkId, props, callback)}
      />
    </>
  }

  renderClient() {
    const client = this.state.client || { data: {} }
    const { classes, intl } = this.props
    return <>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" to='/' className={classes.link}>
          <HomeIcon className={classes.icon} />
          <FormattedMessage id='toDashboard' />
        </Link>
        <Typography color="textPrimary" className={classes.link}>
          <VerifiedUserIcon className={classes.icon} />
          {client.data.name || intl.formatMessage({ id: 'anonymousClient' })}
        </Typography>
      </Breadcrumbs>
      <Client
        editable={true}
        authorizable={true}
        client={client}
        onClientUpdate={(clientId, props, callback) => this.updateClient(clientId, props, callback)}
        onClientAuthorize={(clientId, callback) => this.authorizeClient(clientId, callback)}
      />
    </>
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/">
            <DashboardPage
              sources={this.state.sources}
              sinks={this.state.sinks}
              clients={this.state.clients}
              onAddSource={(callback) => this.addSource(callback)}
              onAddSink={(type, callback) => this.addSink(type, callback)}
              onRemoveSink={(sinkId, callback) => this.removeSink(sinkId, callback)}
              onContextRequest={(sinkId, callback) => this.createContext(sinkId, callback)}
              onRevoke={(sinkId, callback) => this.revokeSink(sinkId, callback)}
              onSinkUpdate={(sinkId, props, callback) => this.updateSink(sinkId, props, callback)}
            />
          </Route>
          <Route exact path="/r/:sourceId">
            {this.renderSource()}
          </Route>
          <Route exact path="/c/:clientId">
            {this.renderClient()}
          </Route>
        </Switch>
      </Router>
    )
  }
}

export default withStyles(styles)(injectIntl(UserPage))
