export const messages = {
  description: 'Notify events more easily',
  loginDescription: 'Service is in preparation.',
  openInquiry: 'Contact Us',
  signout: 'Sign Out',
  submit: 'Submit',
  headerSources: 'Channels',
  headerSinks: 'Sinks',
  headerClients: 'Sources',
  newSource: 'Create New Channel',
  headerNewClientBasedSource: 'Create a notification channel using the notifyg client',
  headerNewServerBasedSource: 'Create a simple notification channel',
  descriptionNewClientBasedSource: 'You can create a secure notification channel by using the client tool notifyg. Execute the following code at the notification source.',
  descriptionNewServerBasedSource: 'You can create a notification channel that can be used by any web client. Press Create New Channel.',
  noSinks: 'No sinks',
  noChannels: 'No channels. Please register a channel according to the Examples.',
  anonymousClient: 'New Client',
  authorizeClient: 'Authorize',
  noClients: 'No clients',
  addSink: 'Add sink',
  addingSink: 'Adding sink...',
  newSinkDialogTitle: 'Select new sink type',
  addToSlack: 'Add to Slack',
  slackWorkspace: 'Workspace',
  slackChannel: 'Channel',
  slackChannelNotSelected: 'Select destination',
  revokeSink: 'Disconnect',
  toDashboard: 'Dashboard',
  sinkSelected: 'Send to this sink',
  sourceExpiration: 'Expiration',
  sourceLimit: 'Max. number of notifications',
  anonymousSource: 'New Source',
  noExpiration: 'No expiration',
  noSinksSelected: 'No sink is set.',
  sinkNameMail: 'Send via e-mail',
  inquirySending: 'Sending...',
  inquirySent: 'Transmitted.',
  inquiryDescription: 'Please fill the form below to inquire about this application. We will reply to you by e-mail later.',
  inquiryLabelUserID: 'User ID',
  inquiryHelperTextUserID: 'This information will be used to confirm the details of your inquiry.',
  inquiryLabelMail: 'Contact e-mail address',
  inquiryHelperTextMail: 'We will use the information only for the purpose of replying to your inquiry.',
  inquiryPlaceholderMail: 'Please enter your contact e-mail address.',
  inquiryLabelText: 'Contents of inquiry',
  inquiryPlaceholderText: 'Please enter the details of your inquiry.',
  inquiryHelperTextText: 'Please fill in the question, including the problem\'s situation. Please do not include your name or other personal information as much as possible.',
  errorUserLoad: "Can't access user data.",
  errorUpdateSink: 'Failed to update sinks.',
  errorUpdateSource: 'Failed to update source.',
  errorSignOut: 'Failed to sign out.',
  errorAddSource: 'Failed to create source.',
  errorSubmitInquiry: 'Failed to send your inquiry.',
  errorAuthorizeClient: 'Failed to authorize client.',
  errorUpdateClient: 'Failed to update client.',
}
