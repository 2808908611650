import { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import firebase from 'firebase/app'
import { withStyles } from '@material-ui/styles'
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import GoogleButton from 'react-google-button'
import Examples from '../components/Examples.js'

const styles = {
  header: {
    padding: '2em',
    backgroundColor: '#ddd',
  },
  largeIcon: {
    width: '20vw',
    height: '20vw',
  },
  titlePanel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2em',
  },
  title: {
    fontSize: '3em',
    fontWeight: 'bold',
    color: 'black',
  },
  login: {
    fontSize: '1.2em',
    padding: '2em',
  },
  examples: {
    paddingBottom: '1em',
  }
}

class AnonymousPage extends Component {
  signIn() {
    const provider = new firebase.auth.GoogleAuthProvider()
    if (!this.props.onSignIn) {
      return
    }
    this.props.onSignIn(provider)
  }

  render() {
    const { classes } = this.props
    return (
      <Container maxWidth='lg'>
        <Paper elevation={0} className={classes.header}>
          <Grid container>
            <Grid item sm='12' className={classes.titlePanel}>
              <img alt='notify.guru' src='/logo1024transparent.png' className={classes.largeIcon} />
              <Typography component="h1" variant="h2" color="inherit" noWrap className={classes.title}>
                <FormattedMessage id='description' />
              </Typography>
            </Grid>
            <Grid item sm='5' className={classes.login}>
              <p>
                <FormattedMessage id='loginDescription' />
              </p>
              <GoogleButton
                onClick={() => this.signIn()}
              />
            </Grid>
            <Grid item sm='7'>
              <Paper elevation={0} className={classes.examples}>
                <Examples maxWidth='sm'/>
              </Paper>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    );
  }
}

export default withStyles(styles)(AnonymousPage)
