import { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { withRouter } from 'react-router'
import { withStyles } from '@material-ui/styles'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import CodeIcon from '@material-ui/icons/Code'
import CloudIcon from '@material-ui/icons/Cloud'
import DevicesIcon from '@material-ui/icons/Devices'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import NewSource from '../components/NewSource.js'
import Sinks from '../components/Sinks.js'
import Source from '../components/Source.js'
import Client from '../components/Client.js'

const DEFAULT_VISIBLE_SOURCES = 5
const DEFAULT_VISIBLE_CLIENTS = 5
const styles = (theme) => ({
  root: {
    marginBottom: '1em',
  },
  noChannels: {
    fontSize: '1.2em',
    textAlign: 'center',
    marginTop: '2em',
    marginBottom: '2em',
    color: '#888',
  }
})

const HASH_TABS = ['#start', '#channels', '#sinks', '#clients']

class DashboardPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      expand: false,
      selectedTab: 0,
    }
  }

  addSink(type, callback) {
    if (!this.props.onAddSink) {
      return
    }
    this.props.onAddSink(type, callback)
  }

  removeSink(sinkId, callback) {
    if (!this.props.onRemoveSink) {
      return
    }
    this.props.onRemoveSink(sinkId, callback)
  }

  createContext(sinkId, callback) {
    if (!this.props.onContextRequest) {
      return
    }
    this.props.onContextRequest(sinkId, callback)
  }

  revokeSink(type, callback) {
    if (!this.props.onRevoke) {
      return
    }
    this.props.onRevoke(type, callback)
  }

  updateSink(type, props, callback) {
    if (!this.props.onSinkUpdate) {
      return
    }
    this.props.onSinkUpdate(type, props, callback)
  }

  createSource(callback) {
    if (!this.props.onAddSource) {
      return
    }
    this.props.onAddSource(callback)
  }

  collapseSources() {
    this.setState({
      expand: false,
    })
  }

  expandSources() {
    this.setState({
      expand: true,
    })
  }

  collapseClients() {
    this.setState({
      expand: false,
    })
  }

  expandClients() {
    this.setState({
      expand: true,
    })
  }

  selectTab(event, newValue) {
    this.props.history.push(`${this.props.location.pathname}${HASH_TABS[newValue]}`)
  }

  renderSources() {
    const { classes } = this.props
    let sources = this.props.sources || []
    if (!this.state.expand && sources.length > DEFAULT_VISIBLE_SOURCES) {
      sources = sources.slice(0, DEFAULT_VISIBLE_SOURCES)
    }
    const newSource = <NewSource
      onAdd={(callback) => this.createSource(callback)}
    />
    if (sources.length === 0) {
      return <Container className={classes.noChannels}>
        {newSource}
        <FormattedMessage id='noChannels' />
      </Container>
    }
    return <Container>
      {newSource}
      {sources.map((source) => <Source
          key={source.id}
          source={source}
          link={(source) => `/r/${source.id}`}
        />)}
      {this.state.expand ?
        <Button
          fullWidth
          onClick={() => this.collapseSources()}
        >
          <ExpandLessIcon />
        </Button> : <Button
          fullWidth
          onClick={() => this.expandSources()}
        >
          <ExpandMoreIcon />
        </Button>}
    </Container>
  }

  renderClients() {
    const { classes } = this.props
    let clients = this.props.clients || []
    if (!this.state.expand && clients.length > DEFAULT_VISIBLE_CLIENTS) {
      clients = clients.slice(0, DEFAULT_VISIBLE_CLIENTS)
    }
    if (clients.length === 0) {
      return <Container className={classes.noClients}>
        <FormattedMessage id='noClients' />
      </Container>
    }
    return <>
      {clients.map((client) => <Client
          key={client.id}
          client={client}
          link={(client) => `/c/${client.id}`}
        />)}
      {this.state.expand ?
        <Button
          fullWidth
          onClick={() => this.collapseClients()}
        >
          <ExpandLessIcon />
        </Button> : <Button
          fullWidth
          onClick={() => this.expandClients()}
        >
          <ExpandMoreIcon />
        </Button>}
    </>
  }

  render() {
    const { classes } = this.props
    const selectedTabCands = HASH_TABS
      .map((tab, index) => [tab, index])
      .filter((tabWithIndex) => tabWithIndex[0] === this.props.location.hash)
    const selectedTab = selectedTabCands.length === 0 ? 0 : selectedTabCands[0][1]
    return <>
      <Paper className={classes.root}>
        <Tabs
          value={selectedTab}
          onChange={(event, newValue) => this.selectTab(event, newValue)}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab
            icon={<DevicesIcon />}
            label={<FormattedMessage id='headerSources' />}
          />
          <Tab
            icon={<CloudIcon />}
            label={<FormattedMessage id='headerSinks' />}
          />
          <Tab
            icon={<VerifiedUserIcon />}
            label={<FormattedMessage id='headerClients' />}
          />
        </Tabs>
      </Paper>

      {selectedTab === 0 && this.renderSources()}
      {selectedTab === 1 && <Sinks
          sinks={this.props.sinks}
          onAddSink={(type, callback) => this.addSink(type, callback)}
          onRemoveSink={(sinkId, callback) => this.removeSink(sinkId, callback)}
          onContextRequest={(sinkId, callback) => this.createContext(sinkId, callback)}
          onRevoke={(sinkId, callback) => this.revokeSink(sinkId, callback)}
          onUpdate={(sinkId, props, callback) => this.updateSink(sinkId, props, callback)}
        />}
      {selectedTab === 2 && this.renderClients()}
    </>
  }
}

export default withStyles(styles)(withRouter(DashboardPage))
