import { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { withStyles } from '@material-ui/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import { sinkTypes } from '../sink-types/'

const styles = (theme) => ({
  root: {
    margin: theme.spacing(2),
  },
})

class NewSinkDialog extends Component {
  addNewSink(typeDef) {
    if (!this.props.onAddSink) {
      return
    }
    this.props.onAddSink(typeDef.type)
  }

  close() {
    if (!this.props.onClose) {
      return
    }
    this.props.onClose()
  }

  render() {
    const { classes, intl } = this.props
    return (
      <Dialog
        onClose={() => this.close()}
        aria-labelledby="new-sink-dialog-title"
        open={this.props.open}>
        <DialogTitle id="new-sink-dialog-title">
          <FormattedMessage id='newSinkDialogTitle' />
        </DialogTitle>
        <Box className={classes.root}>
          {sinkTypes.filter(typeDef => typeDef.canCreate).map(typeDef =>
            <Button
              key={typeDef.type}
              fullWidth
              variant="contained"
              color="secondary"
              onClick={() => this.addNewSink(typeDef)}
            >
              {typeDef.name(intl)}
            </Button>)}
        </Box>
      </Dialog>
    )
  }
}

export default withStyles(styles)(injectIntl(NewSinkDialog))
