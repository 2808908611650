import { Component } from 'react'
import clsx from 'clsx'
import { withStyles } from '@material-ui/styles'
import Container from '@material-ui/core/Container'
import Link from '@material-ui/core/Link'
import GitHubIcon from '@material-ui/icons/GitHub'
import TwitterIcon from '@material-ui/icons/Twitter'

const styles = (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '2em',
    color: '#888',
  },
  link: {
    color: '#888',
  },
  firstLink: {
    marginLeft: '1em',
  }
})

class Footer extends Component {
  render() {
    const { classes } = this.props
    return (
      <Container maxWidth={this.props.maxWidth || 'sm'} className={classes.root}>
        <p>
          Copyright &copy; 2021 Satoshi Yazawa, 'YZWLAB'
        </p>
        <Link href='https://github.com/yacchin1205/' className={clsx(classes.link, classes.firstLink)}>
          <GitHubIcon />
        </Link>
        <Link href='https://twitter.com/yacchin1205/' className={classes.link}>
          <TwitterIcon />
        </Link>
      </Container>
    )
  }
}

export default withStyles(styles)(Footer)
