export const messages = {
  description: 'もっと気軽に 通知を使おう',
  loginDescription: 'サービス準備中です。',
  openInquiry: 'お問い合わせ',
  signout: 'ログアウト',
  submit: '送信',
  headerSources: 'チャンネル',
  headerSinks: '通知先',
  headerClients: '接続元',
  newSource: '通知チャンネルを追加',
  headerNewClientBasedSource: 'notifygクライアントで通知チャンネルを作成',
  headerNewServerBasedSource: 'シンプルな通知チャンネルを作成',
  descriptionNewClientBasedSource: 'クライアントツール notifyg を利用することで安全な通知チャンネルを作成できます。通知元で以下のコードを実行してください。',
  descriptionNewServerBasedSource: '任意のWebクライアントで利用可能な通知チャンネルを作成できます。新規チャンネルの作成を押してください。',
  noSinks: '通知先はありません',
  noChannels: 'チャンネルはありません。通知の実行サンプル を参考に、チャンネルを登録してください。',
  anonymousClient: '新規接続元',
  authorizeClient: '許可',
  noClients: '許可された接続元はありません',
  addSink: '通知先を追加',
  addingSink: '通知先を追加しています...',
  newSinkDialogTitle: '新規通知先のタイプを選択',
  addToSlack: 'Slackに追加',
  slackWorkspace: 'ワークスペース',
  slackChannel: 'チャンネル',
  slackChannelNotSelected: '送信先を選択',
  revokeSink: '接続解除',
  toDashboard: 'ダッシュボード',
  sinkSelected: 'この通知先に送信',
  sourceExpiration: '有効期限',
  sourceLimit: '最大通知回数',
  anonymousSource: '新規ソース',
  noExpiration: '期限未設定',
  noSinksSelected: '通知先が設定されていません。',
  sinkNameMail: 'メールで送信',
  inquirySending: '送信中...',
  inquirySent: '送信しました',
  inquiryDescription: '本アプリに関するお問い合わせは以下のフォームからお願いします。追って、メールにてご連絡差し上げます。',
  inquiryLabelUserID: 'ユーザID',
  inquiryHelperTextUserID: 'お問い合わせに関する状況の確認に使用します。',
  inquiryLabelMail: '連絡先メールアドレス',
  inquiryHelperTextMail: 'お問い合わせへの返信の目的にのみ利用します。',
  inquiryPlaceholderMail: '連絡先メールアドレスを入力してください。',
  inquiryLabelText: 'お問い合わせ内容',
  inquiryPlaceholderText: 'お問い合わせの内容を入力してください。',
  inquiryHelperTextText: '問題の発生状況など、質問内容を記入してください。氏名等の個人情報はできる限り記載しないようにしてください。',
  errorUserLoad: 'ユーザデータにアクセスできませんでした。',
  errorUpdateSink: '通知先の更新に失敗しました。',
  errorUpdateSource: 'ソースの更新に失敗しました。',
  errorSignOut: 'ログアウトできませんでした。',
  errorAddSource: '通知チャンネルの作成に失敗しました。',
  errorSubmitInquiry: 'お問い合わせの送信に失敗しました。',
  errorAuthorizeClient: '接続元の承認に失敗しました。',
  errorUpdateClient: '接続元の更新に失敗しました。',
}
