import { Component } from 'react'
import { injectIntl } from 'react-intl'
import moment from 'moment'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import TextField from '@material-ui/core/TextField'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import { withStyles } from '@material-ui/styles'
import { withRouter } from 'react-router'
import Sinks from './Sinks.js'
import NameLabel from './NameLabel.js'
import SimpleExamples from './SimpleExamples.js'

const styles = (theme) => ({
  root: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
  expiration: {
    textAlign: 'right',
  },
  toSinkIcon: {
    width: 32,
    height: 32,
  },
  toSinks: {
    textAlign: 'center',
    marginTop: '2em',
    marginBottom: '2em',
  },
})

class Source extends Component {
  addSink(type, callback) {
    if (!this.props.onAddSink) {
      return
    }
    this.props.onAddSink(type, callback)
  }

  removeSink(sinkId, callback) {
    if (!this.props.onRemoveSink) {
      return
    }
    this.props.onRemoveSink(sinkId, callback)
  }

  createContext(sinkId, callback) {
    if (!this.props.onContextRequest) {
      return
    }
    this.props.onContextRequest(sinkId, callback)
  }

  revokeSink(type, callback) {
    if (!this.props.onRevoke) {
      return
    }
    this.props.onRevoke(type, callback)
  }

  updateSink(type, props, callback) {
    if (!this.props.onSinkUpdate) {
      return
    }
    this.props.onSinkUpdate(type, props, callback)
  }

  selectSink(sinkId, selected) {
    if (!this.props.onSinkSelect) {
      return
    }
    this.props.onSinkSelect(sinkId, selected)
  }

  changeSourceName(name, callback) {
    if (!this.props.onSourceUpdate) {
      return
    }
    this.props.onSourceUpdate(this.props.source.id, { name }, callback)
  }

  changeSourceExpiration(date, callback) {
    if (!this.props.onSourceUpdate) {
      return
    }
    const expiration = date.getTime()
    this.props.onSourceUpdate(this.props.source.id, { expiration }, callback)
  }

  changeSourceLimit(value, callback) {
    if (!this.props.onSourceUpdate) {
      return
    }
    const limit = parseInt(value)
    this.props.onSourceUpdate(this.props.source.id, { limit }, callback)
  }

  renderExpiration() {
    const { intl } = this.props
    const source = this.props.source || { data: {} }
    if (this.props.editable) {
      return <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          id="date-picker-inline"
          label={intl.formatMessage({ id: 'sourceExpiration' })}
          value={source.data.expiration ?
            new Date(source.data.expiration) :
            new Date()}
          onChange={(date) => this.changeSourceExpiration(date)}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </MuiPickersUtilsProvider>
    }
    return <TextField
      label={intl.formatMessage({ id: 'sourceExpiration' })}
      defaultValue={source.data.expiration ? moment(new Date(source.data.expiration)).fromNow() : ''}
      InputProps={{
        readOnly: true,
      }}
    />
  }

  renderLimit() {
    const { intl } = this.props
    const source = this.props.source || { data: {} }
    return <TextField
      label={intl.formatMessage({ id: 'sourceLimit' })}
      type='number'
      defaultValue={source.data.limit || 0}
      onChange={(event) => this.changeSourceLimit(event.target.value)}
      InputProps={{
        readOnly: !this.props.editable,
      }}
    />
  }

  renderNameLabel() {
    const { intl } = this.props
    const source = this.props.source || { data: {} }
    return <NameLabel
      editable={this.props.editable}
      value={source.data.name || intl.formatMessage({ id: 'anonymousSource' })}
      onChange={(value, callback) => this.changeSourceName(value, callback)}
    />
  }

  render() {
    const { classes } = this.props
    const source = this.props.source || { data: {} }
    return (
      <Box>
        <Paper className={classes.root}>
          <Grid container spacing={1}>
            <Grid item xs={7}>
              {this.props.link ? <a href={this.props.link(source)}>
                  {this.renderNameLabel()}
                </a> : this.renderNameLabel()}
            </Grid>
            <Grid item xs={5} className={classes.expiration}>
              {this.renderExpiration()}
            </Grid>
            {source.data.nosecret && <Grid item xs={12} className={classes.expiration}>
                {this.renderLimit()}
              </Grid>}
          </Grid>
          {source.data.nosecret && this.props.editable && <SimpleExamples sourceId={source.id} />}
        </Paper>
        {this.props.editable && <Container className={classes.toSinks}>
            <ArrowDownwardIcon className={classes.toSinkIcon} />
          </Container>}
        {this.props.editable && <Sinks
            selectable={true}
            selected={this.props.selectedSinks}
            sinks={this.props.sinks}
            onAddSink={(type, callback) => this.addSink(type, callback)}
            onRemoveSink={(sinkId, callback) => this.removeSink(sinkId, callback)}
            onContextRequest={(sinkId, callback) => this.createContext(sinkId, callback)}
            onRevoke={(sinkId, callback) => this.revokeSink(sinkId, callback)}
            onUpdate={(sinkId, props, callback) => this.updateSink(sinkId, props, callback)}
            onSelect={(sinkId, selected) => this.selectSink(sinkId, selected)}
          />}
      </Box>
    )
  }
}

export default withStyles(styles)(withRouter(injectIntl(Source)))
