import { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { withStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import Examples from './Examples.js'

const styles = (theme) => ({
  option: {
    padding: '1em',
  },
  title: {
    fontSize: '2em',
    paddingBottom: '0.5em',
  },
  description: {
    fontSize: '1.2em',
    padding: '0.5em',
  },
})

class NewSource extends Component {
  constructor(props) {
    super(props)
    this.state = {
      adding: false,
    }
  }

  addNewSource() {
    if (!this.props.onAdd) {
      return
    }
    this.setState({
      adding: true,
    }, () => {
      this.props.onAdd(() => {
        this.setState({
          adding: false,
        })
      })
    })
  }

  render() {
    const { classes } = this.props
    return (
      <Container>
        <Container className={classes.option}>
          <Typography variant="h2" component="h2" className={classes.title}>
            <FormattedMessage id='headerNewServerBasedSource'/>
          </Typography>
          <Typography variant="p" component="p" className={classes.description}>
            <FormattedMessage id='descriptionNewServerBasedSource'/>
          </Typography>
        </Container>
        <Container>
          <Button
            disabled={this.state.adding}
            variant="contained"
            color="primary"
            onClick={() => this.addNewSource()}
          >
            <AddIcon />
            <FormattedMessage id='newSource'/>
          </Button>
        </Container>
      </Container>
    )
  }
}

export default withStyles(styles)(NewSource)
