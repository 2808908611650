import { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import EditIcon from '@material-ui/icons/Edit'

const styles = (theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: 0,
    },
  },
  title: {
    fontSize: '2em',
  },
})

class NameLabel extends Component {
  constructor(props) {
    super(props)

    this.state = {
      editing: false,
    }
  }

  change(event) {
    const value = event.target.value
    this.setState({
      editing: false,
    }, () => {
      if (!this.props.onChange) {
        return
      }
      this.props.onChange(value)
    })
  }

  keyPress(event) {
    if(event.key !== 'Enter'){
      return
    }
    this.change(event)
  }

  startEdit() {
    this.setState({
      editing: true,
    })
  }

  renderEditor() {
    return <TextField
      defaultValue={this.props.value || ''}
      onBlur={(event) => this.change(event)}
      onKeyPress={(event) => this.keyPress(event)}
    />
  }

  renderLabel() {
    const { classes } = this.props
    return <>
      <Typography variant="h2" component="h2" className={classes.title}>
        {this.props.value}
      </Typography>
      {this.props.editable && <Button onClick={() => this.startEdit()}>
          <EditIcon />
        </Button>}
    </>
  }

  render() {
    const { classes } = this.props
    return (
      <Box className={classes.root}>
        {this.state.editing ? this.renderEditor() : this.renderLabel()}
      </Box>
    )
  }
}

export default withStyles(styles)(NameLabel)
