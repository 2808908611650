import Avatar from '@material-ui/core/Avatar'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import SlackEditor from './SlackEditor.js'

export const sinkTypes = [{
  type: 'slack',
  name: () => 'Slack',
  image: (className) => <Avatar
    className={className}
    alt='slack'
    src={'/images/slack.svg'}
  />,
  canCreate: true,
  titleEditable: true,
  removable: true,
  editor: (props) => <SlackEditor {...props} />
}, {
  type: 'mail',
  name: (intl) => intl.formatMessage({ id: 'sinkNameMail' }),
  image: (className) => <MailOutlineIcon />,
  canCreate: false,
  titleEditable: false,
  removable: false,
  editor: (props) => null
}]
