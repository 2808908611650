import { Component } from 'react'
import Context from './Context'
import Base from './Base'

class App extends Component {
  render() {
    return (
      <Context>
        <Base />
      </Context>
    )
  }
}

export default App
