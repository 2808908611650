import { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import moment from 'moment'
import { withStyles } from '@material-ui/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { sinkTypes } from '../sink-types/'
import NameLabel from './NameLabel.js'

const styles = (theme) => ({
  root: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
  header: {
    display: 'flex',
    '& > *': {
      margin: 0,
    },
  },
  icon: {
    width: '1.8em',
    height: '1.8em',
  },
  revoke: {
    textAlign: 'right',
  },
  updated: {
    textAlign: 'right',
    fontSize: '0.9em',
    color: '#444',
  }
})

class Sink extends Component {
  remove() {
    if (!this.props.onRemove) {
      return
    }
    this.props.onRemove()
  }

  requestContext(callback) {
    if (!this.props.onContextRequest) {
      return
    }
    this.props.onContextRequest(callback)
  }

  revoke(callback) {
    if (!this.props.onRevoke) {
      return
    }
    this.props.onRevoke(callback)
  }

  update(props, callback) {
    if (!this.props.onUpdate) {
      return
    }
    this.props.onUpdate(props, callback)
  }

  changeSelection(event) {
    if (!this.props.onSelect) {
      return
    }
    this.props.onSelect(event.target.checked)
  }

  changeName(name, callback) {
    this.update({ name }, callback)
  }

  render() {
    const { classes, intl } = this.props
    const targetTypes = sinkTypes.filter((sinkType) => sinkType.type === this.props.sink.data.type)
    return (
      <Paper className={classes.root}>
        <Grid container spacing={1}>
          {this.props.selectable &&
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox
                      checked={this.props.selected}
                      onChange={(event) => this.changeSelection(event)}
                    />}
                label={<FormattedMessage id='sinkSelected' />}
              />
            </Grid>}
          <Grid item xs={8} className={classes.header}>
            {targetTypes.length > 0 && targetTypes[0].image(classes.icon)}
            <NameLabel
              editable={targetTypes.length > 0 ? targetTypes[0].titleEditable : true}
              value={this.props.sink.data.name || (targetTypes.length > 0 ? targetTypes[0].name(intl) : this.props.sink.data.type)}
              onChange={(value, callback) => this.changeName(value, callback)}
            />
          </Grid>
          <Grid item xs={4} className={classes.updated}>
            {moment(new Date(this.props.sink.data.updated)).fromNow()}
          </Grid>
          <Grid item xs={12}>
            {targetTypes.length > 0 && targetTypes[0].editor({
              sink: this.props.sink,
              onContextRequest: (callback) => this.requestContext(callback),
              onRevoke: (callback) => this.revoke(callback),
              onUpdate: (props, callback) => this.update(props, callback),
            })}
          </Grid>
          {targetTypes.length > 0 && targetTypes[0].removable && <Grid item xs={12} className={classes.revoke}>
              <Button onClick={() => this.remove()}>
                <DeleteIcon />
              </Button>
            </Grid>}
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(injectIntl(Sink))
