import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles'

import Container from '@material-ui/core/Container'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  button: {
    textAlign: 'right',
  },
});

class InquiryForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sent: false,
      sending: false,
      text: '',
      mail: '',
    }
  }

  submit() {
    if (!this.props.onSubmit) {
      return;
    }
    const content = {
      time: Date.now(),
      mail: this.state.mail,
      text: this.state.text,
    }
    this.setState({
      sending: true,
    }, () => {
      this.props.onSubmit(content, (success) => {
        this.setState({
          sending: false,
          sent: success,
        });
      });
    });
  }

  render() {
    const { classes, intl } = this.props;
    return <Container className={classes.root}>
      <p>
        <FormattedMessage id='inquiryDescription' />
      </p>
      {!this.state.sent && <>
        {this.state.sending && <div>
            <FormattedMessage id='inquirySending' />
          </div>}
        <FormControl fullWidth margin='normal'>
          <FormLabel htmlFor='uid'>
            <FormattedMessage id='inquiryLabelUserID' />
          </FormLabel>
          <TextField
            id='uid'
            aria-describedby='uid-helper-text'
            readOnly={true}
            disabled={true}
            value={this.props.user && this.props.user.uid}
          />
          <FormHelperText id="uid-helper-text">
            <FormattedMessage id='inquiryHelperTextUserID' />
          </FormHelperText>
        </FormControl>
        <FormControl fullWidth margin='normal'>
          <FormLabel htmlFor='mail'>
            <FormattedMessage id='inquiryLabelMail' />
          </FormLabel>
          <TextField
            id='mail'
            inputProps={{ "data-testid": "mail" }}
            aria_describedby='mail-helper-text'
            disabled={this.state.sending}
            placeholder={intl.formatMessage({ id: 'inquiryPlaceholderMail'})}
            onChange={ev => this.setState({ mail: ev.target.value })} />
          <FormHelperText id='mail-helper-text'>
            <FormattedMessage id='inquiryHelperTextMail' />
          </FormHelperText>
        </FormControl>
        <FormControl fullWidth margin='normal'>
          <FormLabel htmlFor='text'>
            <FormattedMessage id='inquiryLabelText' />
          </FormLabel>
          <TextField
            as='textarea'
            id='text'
            aria-describedby='text-helper-text'
            inputProps={{ "data-testid": "text" }}
            rows={10}
            multiline
            disabled={this.state.sending}
            placeholder={intl.formatMessage({ id: 'inquiryPlaceholderText' })}
            onChange={ev => this.setState({ text: ev.target.value })} />
          <FormHelperText id='text-helper-text'>
            <FormattedMessage id='inquiryHelperTextText' />
          </FormHelperText>
        </FormControl>
        <Container className={classes.button}>
          <Button
            data-testid='submit'
            disabled={this.state.sending || !this.state.text || !this.state.mail}
            variant="contained"
            color="primary"
            onClick={() => this.submit()}
          >
            <FormattedMessage id='submit' />
          </Button>
        </Container>
      </>}
      {this.state.sent && <div>
        <FormattedMessage id='inquirySent' />
      </div>}
    </Container>;
  }
}

export default withStyles(styles)(injectIntl(InquiryForm));
